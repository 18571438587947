import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Layout from '../layout';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Badges`}</h1>
    <p><a parentName="p" {...{
        "href": "https://system-ui.com/theme"
      }}><img parentName="a" {...{
          "src": "https://flat.badgen.net/badge/system-ui/theme/black",
          "alt": "system-ui/theme"
        }}></img></a></p>
    <pre><code parentName="pre" {...{
        "className": "language-md"
      }}>{`[![system-ui/theme](https://flat.badgen.net/badge/system-ui/theme/black)](https://system-ui.com/theme)
`}</code></pre>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      